/**
 * This file is needed to avoid a circular dependency between navigation.routes
 * and bottom-navigation.component
 */
export enum NavigationRouteNames {
  // Tabs
  HOME = 'home',
  PORTFOLIOS = 'portfolios',
  GAMES = 'games',
  FAVOURITES = 'favourites',
  DISCOVER = 'discover',
  CHATS = 'chats',

  // Others
  ACTIVITIES = 'activities',
  ADD_TRANSACTIONS = 'add-transactions',
  AUTH = 'auth',
  CHAT_ROOMS = 'chat-rooms',
  COMPARISON = 'comparison',
  DIVIDEND_CALENDAR = 'dividend-calendar',
  FOLLOWERS = 'followers',
  FOLLOWING = 'following',
  GAME_ASSETS = 'game-assets',
  GAME_OVERVIEW = 'game-overview',
  GAME_QUIZ = 'game-quiz',
  GAME_QUIZZES = 'game-quizzes',
  GAME_RANKING = 'game-ranking',
  INSTRUMENTS = 'instruments',
  INSTRUMENTS_BY_COUNTRY = 'by-country',
  INSTRUMENTS_BY_SECTOR = 'by-sector',
  INSTRUMENT_CATEGORY_OVERVIEW = 'category-overview',
  INSTRUMENT_FEED = 'feed',
  INSTRUMENT_MARKET_NEWS = 'market-news',
  INVITE = 'invitations',
  LIKES = 'likes',
  LINKING = 'linking',
  NEW_SIMULATION_PORTFOLIO = 'new-simulation-portfolio',
  PENDING = 'pending',
  PORTFOLIOS_LIST = 'portfolios-list',
  PORTFOLIOS_MENU = 'portfolios-menu',
  PORTFOLIOS_REPORT = 'portfolios-report',
  PORTFOLIO_INSIGHTS = 'insights',
  PORTFOLIO_ITEMS = 'portfolio-items',
  PORTFOLIO_PERF_RISKS = 'perf-risks',
  SEARCH = 'search',
  SETTINGS = 'settings',
  TRANSACTION = 'transaction',
  TRANSACTIONS = 'transactions',
  USERS = 'users',
  USER_ACTIVITIES = 'user-activities',
}
