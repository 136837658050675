export * from './lib/account-linking.route-names';
export * from './lib/navigation.route-names';
export * from './lib/settings.route-names';

export function extractBaseRoute(routeUrl: string): string {
  const baseRoute = routeUrl.split(/[/?]/)[1];
  return '/' + baseRoute;
}

export function extractQueryParams(
  routeUrl: string,
  prefix?: string,
): Record<string, string> | undefined {
  const url = new URL(routeUrl, 'http://example.com');
  if (!url.searchParams.size) return undefined;

  const params: Record<string, string> = {};
  url.searchParams.forEach((value, key) => {
    if (prefix && !key.startsWith(`${prefix}_`)) return;

    params[key] = value;
  });
  return params;
}
