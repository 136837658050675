export enum SettingsRoutesNames {
  SETTINGS_CONFIRM_EMAIL = 'confirm-email',
  SETTINGS_CONFIRM_PASSWORD = 'confirm-password',
  SETTINGS_CURRENCY = 'currency',
  SETTINGS_DELETE_ACCOUNT = 'account-management',
  SETTINGS_EMAIL = 'email',
  SETTINGS_LANGUAGE = 'language',
  SETTINGS_LICENSES = 'licenses',
  SETTINGS_OTHERS = 'others',
  SETTINGS_PASSWORD = 'password',
  SETTINGS_PHONE = 'phone',
  SETTINGS_PORTFOLIOS = 'portfolios',
  SETTINGS_PORTFOLIOS_ACTIONS = 'portfolios-management',
  SETTINGS_PRIVACY = 'privacy',
  SETTINGS_PROFILE = 'profile',
  SETTINGS_ACCOUNT = 'account',
  SETTINGS_USERNAME = 'username',
}
